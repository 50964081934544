'use client';
import { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { AtSign, Menu } from 'lucide-react';
import MenuComponent from '@/components/nav/MenuComponent';
import ModalComponent from '@/components/nav/ModalComponent';
import { useSound } from '@/app/context/soundContext';
import LocaleSwitcher from './LocaleSwitcher';
export default function Header() {
    const { playHoverSound, playClickSound } = useSound();

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState<string | null>(null);

    const searchParams = useSearchParams();
    const pathname = usePathname();

    // Открываем меню и добавляем параметр в URL
    const toggleMenu = () => {
        if (!isModalOpen) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    // Открываем модальное окно и добавляем параметр в URL
    const openModal = (menuItem: string) => {
        setActiveMenu(menuItem);
        setIsModalOpen(true);
        setIsMenuOpen(false);

        // Добавляем параметр `page` в URL с помощью router.replace
        const newUrl = `${pathname}?page=${menuItem}`;
        window.history.pushState(null, '', newUrl); // Изменяет URL без перезагрузки страницы
    };

    // Закрываем модальное окно и очищаем параметр URL
    const closeModal = () => {
        setIsModalOpen(false);
        setActiveMenu(null);

        // Удаляем параметр `page` из URL
        const newUrl = `${pathname}`;
        window.history.pushState(null, '', newUrl); // Изменяет URL без перезагрузки страницы
    };

    // При первом рендере проверяем URL на наличие параметра `page`
    useEffect(() => {
        const page = searchParams.get('page');
        if (page) {
            setActiveMenu(page);
            setIsModalOpen(true); // Открываем модальное окно, если параметр присутствует
        }
    }, [searchParams]);

    // Управление прокруткой страницы
    useEffect(() => {
        // Функция для закрытия меню и модального окна при нажатии на Escape
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                if (isModalOpen) {
                    closeModal(); // Закрываем модальное окно
                } else if (isMenuOpen) {
                    toggleMenu(); // Закрываем меню
                }
            }
        };

        // Добавляем обработчик события keydown
        document.addEventListener('keydown', handleKeyDown);

        // Обрабатываем блокировку скролла
        if (isMenuOpen || isModalOpen) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }

        // Убираем обработчик события и возвращаем скролл при размонтировании компонента
        return () => {
            document.body.style.overflowY = 'auto';
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isMenuOpen, isModalOpen]);

    return (
        <div className='hero__header'>
            <div className='hero__powered-by px-5 flex items-center justify-center w-auto md:w-full'>
                <Menu
                    className='cursor-pointer'
                    onClick={(e) => {
                        //@ts-ignore
                        toggleMenu(e); // Вызов функции для переключения меню
                        //@ts-ignore
                        playClickSound(e); // Вызов функции для воспроизведения звука
                    }}
                    // onMouseEnter={playHoverSound} // Вызов функции для воспроизведения звука при наведении
                />
                <MenuComponent
                    toggleMenu={toggleMenu}
                    openModal={openModal}
                    isOpen={isMenuOpen}
                    isModalOpen={isModalOpen} // Передаем состояние модального окна
                />
            </div>
            <div className='hero__workos-icon flex flex-row'>
                <Image
                    alt='logo'
                    src='/logo-ddrw.svg'
                    sizes='(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 100px'
                    width={120}
                    height={120}
                    priority={true}
                />
            </div>
            <div className='hero__workos-theme px-5 flex items-center justify-center w-auto md:w-full cursor-pointer'>
                <LocaleSwitcher />
            </div>

            {/* Модальное окно */}
            <ModalComponent activeMenu={activeMenu} closeModal={closeModal} isOpen={isModalOpen} />
        </div>
    );
}
