import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/src/components/main/DashboardAnimation.tsx");
;
import(/* webpackMode: "eager" */ "/home/workspace/myru-landing/src/components/main/HeroSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/src/components/main/Product.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/workspace/myru-landing/src/components/ui/word-rotate.tsx");
