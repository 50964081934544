'use client';
import Image from 'next/image';
import { useEffect, useState } from 'react';

const DashboardAnimation = () => {
    const [heights, setHeights] = useState<number[]>([23, 26, 26, 5, 15, 10, 9, 16, 22]);
    useEffect(() => {
        const interval = setInterval(() => {
            // Изменяем высоту каждого бара случайным образом для эффекта анимации
            setHeights(heights.map(() => Math.floor(Math.random() * 30) + 5)); // Высота от 5 до 30px
        }, 1000); // Обновление высоты каждые 1000 мс

        return () => clearInterval(interval); // Очищаем интервал при размонтировании компонента
    }, [heights]);

    return (
        <div className='dashboard-animation-wrapper'>
            <picture>
                <source media='(min-width: 1200px)' srcSet='/background.png 1x, /background.png 2x' width='1440' height='1032' />
                <source media='(max-width: 1199px)' srcSet='/background-mobile.png 1x, /background-mobile.png 2x' width='697' height='506.5' />
                <Image
                    className='dashboard-background'
                    src='/background.png'
                    style={{ color: 'transparent' }}
                    alt='Dashboard background'
                    width={1440}
                    height={1032}
                />
            </picture>

            <div className='dashboard-animation'>
                <div className='dashboard-lines'>
                    <div
                        className='dashboard-lines-item dashboard-lines-item-active'
                        style={
                            {
                                '--mask-image': 'url(/1.png)',
                                '--animation-duration': '5000ms',
                                width: '699.5px',
                                height: '157.5px',
                            } as React.CSSProperties
                        }
                    >
                        <div style={{ width: '699.5px', height: '699.5px', '--start-angle': '225deg' } as React.CSSProperties} />
                    </div>
                    <div
                        className='dashboard-lines-item dashboard-lines-item-active'
                        style={
                            {
                                '--mask-image': 'url(/2.png)',
                                '--animation-duration': '5000ms',
                                width: '715.5px',
                                height: '173.5px',
                            } as React.CSSProperties
                        }
                    >
                        <div style={{ width: '715.5px', height: '715.5px', '--start-angle': '225deg' } as React.CSSProperties} />
                    </div>
                    <div
                        className='dashboard-lines-item dashboard-lines-item-active'
                        style={
                            {
                                '--mask-image': 'url(/3.png)',
                                '--animation-duration': '5000ms',
                                width: '747.5px',
                                height: '222.5px',
                            } as React.CSSProperties
                        }
                    >
                        <div style={{ width: '747.5px', height: '222.5px', '--start-angle': '225deg' } as React.CSSProperties} />
                    </div>
                    <div
                        className='dashboard-lines-item dashboard-lines-item-active'
                        style={
                            {
                                '--mask-image': 'url(/4.png)',
                                '--animation-duration': '5000ms',
                                width: '775.5px',
                                height: '263.5px',
                            } as React.CSSProperties
                        }
                    >
                        <div style={{ width: '775.5px', height: '263.5px', '--start-angle': '225deg' } as React.CSSProperties} />
                    </div>
                    <div
                        className='dashboard-lines-item dashboard-lines-item-active'
                        style={
                            {
                                '--mask-image': 'url(/5.png)',
                                '--animation-duration': '5000ms',
                                width: '811.5px',
                                height: '279.5px',
                            } as React.CSSProperties
                        }
                    >
                        <div style={{ width: '775.5px', height: '263.5px', '--start-angle': '225deg' } as React.CSSProperties} />
                    </div>
                </div>

                <div className='dashboard-application dashboard-application-active dashboard-application-animate'>
                    <div>
                        <div className='dashboard-application-input'>
                            <div style={{ '--transition-delay': '0.2s' } as React.CSSProperties} />
                            <div style={{ '--transition-delay': '0.3s' } as React.CSSProperties} />
                            <div style={{ '--transition-delay': '0.4s' } as React.CSSProperties} />
                            <div style={{ '--transition-delay': '0.5s' } as React.CSSProperties} />
                        </div>
                    </div>
                    <div className='dashboard-application-button'></div>
                </div>
                <div className='dashboard-work dashboard-work-active'>
                    <Image
                        alt='Dashboard Work Inner'
                        loading='lazy'
                        width={436}
                        height={436}
                        decoding='async'
                        className='dashboard-work-inner'
                        style={{ color: 'transparent' }}
                        src='/inner.png'
                    />

                    <div className='dashboard-work-lights'>
                        <div></div>
                        <div></div>
                    </div>

                    <div className='dashboard-work-bars'>
                        <div></div>
                        <div></div>
                    </div>
                </div>
                <div className='dashboard-auth dashboard-auth-active dashboard-auth-animate'>
                    <div>
                        {/* Первая строка "ввода" */}
                        <div className='dashboard-auth-input'>
                            <div className='animate-password' style={{ '--transition-delay': '0.2s' } as React.CSSProperties}></div>
                            <div className='animate-password' style={{ '--transition-delay': '0.3s' } as React.CSSProperties}></div>
                            <div className='animate-password' style={{ '--transition-delay': '0.4s' } as React.CSSProperties}></div>
                        </div>

                        {/* Вторая строка "ввода" */}
                        <div className='dashboard-auth-input'>
                            <div className='animate-password' style={{ '--transition-delay': '0.8s' } as React.CSSProperties}></div>
                            <div className='animate-password' style={{ '--transition-delay': '0.9s' } as React.CSSProperties}></div>
                            <div className='animate-password' style={{ '--transition-delay': '1s' } as React.CSSProperties}></div>
                            <div className='animate-password' style={{ '--transition-delay': '1.1s' } as React.CSSProperties}></div>
                            <div className='animate-password' style={{ '--transition-delay': '1.2s' } as React.CSSProperties}></div>
                            <div className='animate-password' style={{ '--transition-delay': '1.3s' } as React.CSSProperties}></div>
                        </div>
                    </div>
                </div>
                <div className='dashboard-user dashboard-user-active'>
                    <div className='dashboard-user-dot'></div>
                    <div className='dashboard-user-spinner'>
                        <Image alt='Dashboard User Spinner' loading='lazy' width={51.5} height={51.5} src='/spinner.png' />
                    </div>
                </div>
                <div className='dashboard-events dashboard-events-active'>
                    <div className='dashboard-events-spinner' style={{ '--mask-image': 'url(/mask.png)' } as React.CSSProperties}>
                        <div></div>
                    </div>
                </div>
                <div className='dashboard-database dashboard-database-active'>
                    <div className='dashboard-database-bars'>
                        {heights.map((height, index) => (
                            <div
                                key={index}
                                className='bar'
                                style={{
                                    height: `${height}px`,
                                    backgroundColor: '#0f0', // Задайте цвет или стили по вашему желанию
                                    transition: 'height 0.5s ease-in-out', // CSS-анимация плавного изменения высоты
                                }}
                            ></div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardAnimation;
