// app/context/soundContext.tsx
'use client';

import { createContext, useContext, useEffect, useRef } from 'react';
import { Howl } from 'howler';

Howler.autoUnlock = true;

interface SoundContextType {
    playHoverSound: () => void;
    playClickSound: () => void;
}

const SoundContext = createContext<SoundContextType | undefined>(undefined);

export const SoundProvider = ({ children }: { children: React.ReactNode }) => {
    const hoverSoundRef = useRef<Howl | null>(null);
    const clickSoundRef = useRef<Howl | null>(null);

    useEffect(() => {
        // Инициализация звуков
        hoverSoundRef.current = new Howl({
            src: ['/mp3/HOTSPOT.mp3'],
            preload: true,
            html5: true,
        });

        clickSoundRef.current = new Howl({
            src: ['/mp3/CLICK.mp3'],
            preload: true,
            html5: true,
        });
    }, []);

    const playHoverSound = () => {
        hoverSoundRef.current?.play();
    };

    const playClickSound = () => {
        clickSoundRef.current?.play();
    };

    return <SoundContext.Provider value={{ playHoverSound, playClickSound }}>{children}</SoundContext.Provider>;
};

export const useSound = (): SoundContextType => {
    const context = useContext(SoundContext);
    if (context === undefined) {
        throw new Error('useSound must be used within a SoundProvider');
    }
    return context;
};
