export default function AccentLines() {
    return (
        <div className='hero__lines'>
            <div className='hero__wlines'>
                <div className='hero__hline' style={{ gridArea: 'h-line-1', opacity: 0.75 }}></div>
                <div className='hero__hline' style={{ gridArea: 'h-line-2', opacity: 1 }}></div>
                <div className='hero__hline' style={{ gridArea: 'h-line-3', opacity: 1 }}></div>
                <div className='hero__hline' style={{ gridArea: 'h-line-4', opacity: 1 }}></div>
                <div className='hero__hline' style={{ gridArea: 'h-line-5', opacity: 1 }}></div>
                <div className='hero__hline' style={{ gridArea: 'h-line-6', opacity: 1 }}></div>
            </div>
            <div className='hero__vlines'>
                <div className='hero__vline' style={{ gridArea: 'v-line-1', opacity: 1 }}></div>
                <div className='hero__vline' style={{ gridArea: 'v-line-2', opacity: 1 }}></div>
                <div className='hero__vline' style={{ gridArea: 'v-line-3', opacity: 1 }}></div>
                <div className='hero__vline' style={{ gridArea: 'v-line-4', opacity: 1 }}></div>
                <div className='hero__vline' style={{ gridArea: 'v-line-5', opacity: 0.5 }}></div>
                <div className='hero__vline' style={{ gridArea: 'v-line-6', opacity: 0.5 }}></div>
            </div>
        </div>
    );
}
