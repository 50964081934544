'use client';
import AccentLines from '@/components/main/AccentLines';
import Header from '@/components/main/Header';
import Image from 'next/image';
import ClientLayout from '@/components/main/ClientLayout'; // Импорт клиентского компонента
import dynamic from 'next/dynamic';
import { CarouselComponent } from '@/components/swiper/CardSwiper';
import { useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useTranslations } from 'next-intl';

export default function HeroSection() {
    const t = useTranslations('Main');

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeMenu, setActiveMenu] = useState<string | null>(null);

    const router = useRouter();
    const searchParams = useSearchParams();
    const pathname = usePathname();

    // Открываем меню и добавляем параметр в URL
    const toggleMenu = () => {
        if (!isModalOpen) {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    const openModal = (menuItem: string) => {
        const scrollableElement = document.querySelector('body'); // Или другой контейнер, который управляет прокруткой

        if (scrollableElement) {
            // Если уже прокручено до верха, открываем модальное окно сразу
            if (scrollableElement.scrollTop === 0) {
                // Открываем модальное окно
                setActiveMenu(menuItem);
                setIsModalOpen(true);
                setIsMenuOpen(false);

                // Изменяем URL без перезагрузки страницы
                const newUrl = `${window.location.pathname}?page=${menuItem}`;
                window.history.pushState(null, '', newUrl);
            } else {
                // Прокручиваем страницу вверх
                scrollableElement.scrollTo({ top: 0, behavior: 'smooth' });

                // Добавляем обработчик события scroll
                const handleScroll = () => {
                    // Проверяем, достигло ли тело страницы верха
                    if (scrollableElement.scrollTop === 0) {
                        // Удаляем обработчик, когда достигнуто значение прокрутки 0
                        scrollableElement.removeEventListener('scroll', handleScroll);

                        // Открываем модальное окно
                        setActiveMenu(menuItem);
                        setIsModalOpen(true);
                        setIsMenuOpen(false);

                        // Изменяем URL без перезагрузки страницы
                        const newUrl = `${window.location.pathname}?page=${menuItem}`;
                        window.history.pushState(null, '', newUrl);
                    }
                };

                // Добавляем событие scroll
                scrollableElement.addEventListener('scroll', handleScroll);
            }
        }
    };

    // Закрываем модальное окно и очищаем параметр URL
    const closeModal = () => {
        setIsModalOpen(false);
        setActiveMenu(null);

        // Удаляем параметр `page` из URL
        const newUrl = `${pathname}`;
        window.history.pushState(null, '', newUrl); // Изменяет URL без перезагрузки страницы
    };

    return (
        <>
            {/* <div className='heroMa'>
                <p> DDRW</p>
            </div>
            <div className='heroSubP'>
                <p>Представляет</p>
            </div> */}
            <div className='hero hero--intro'>
                <ClientLayout />
                <div className='hero__spotlights'>
                    <div style={{ '--rotate': '20deg', '--scale': '1', '--duration': '5s' } as React.CSSProperties} className='spotlight_spotlight'></div>
                    <div style={{ '--rotate': '0deg', '--scale': '1.02', '--duration': '8s' } as React.CSSProperties} className='spotlight_spotlight'></div>
                    <div style={{ '--rotate': '-20deg', '--scale': '1', '--duration': '4s' } as React.CSSProperties} className='spotlight_spotlight'></div>
                </div>
                <AccentLines />
                <Header />
                <div className='between-lines_wrapper hero__t'>
                    <div className='badge_badge'>
                        <span className='text_text text_text-gradient uppercase'>{t('name')}</span>
                    </div>
                </div>
                <div className='between-lines_wrapper between-lines_wrapper-gradient hero__introducing'>
                    <div className='badge_badge'>
                        <span className='text_text text_text-gradient uppercase'>{t('present')}</span>
                    </div>
                </div>
                <div className='outline-box_box outline-box_box-dots hero__logo'>
                    <Image
                        alt='logo'
                        src='/logo-text.svg'
                        sizes='(max-width: 768px) 50vw, (max-width: 1200px) 25vw, 100px'
                        width={80}
                        height={80}
                        priority={true}
                    />
                </div>
                <div className='outline-box_box outline-box_box-dots hero__headline'>
                    <h2 className='text-center'>
                        <span className='text-base md:text-2xl'>{t('descr')}</span>
                    </h2>
                </div>
                <span className='hero__cross' style={{ gridArea: 'cross-1' } as React.CSSProperties}></span>
                <span className='hero__cross' style={{ gridArea: 'cross-2' } as React.CSSProperties}></span>
                <CarouselComponent toggleMenu={toggleMenu} openModal={openModal} isOpen={isMenuOpen} isModalOpen={isModalOpen} />
            </div>
        </>
    );
}
