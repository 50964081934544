import dynamic from 'next/dynamic';
// import { useState } from 'react';

// Динамический импорт компонента CanvasAnimation
const CanvasAnimation = dynamic(() => import('@/components/main/CanvasAnimation'), {
    ssr: false, // Отключаем SSR для CanvasAnimation
    // loading: () => <Preloader />, // Показываем прелоадер, пока компонент не загружен
});

export default function ClientLayout() {
    // const [isLoaded, setIsLoaded] = useState(false);

    // useEffect(() => {
    //     // Эффект будет запущен только один раз после загрузки компонента
    //     const timer = setTimeout(() => {
    //         setIsLoaded(true); // Устанавливаем флаг после задержки
    //     }, 1000); // Симулируем процесс загрузки, можно убрать, если не нужно

    //     return () => clearTimeout(timer); // Очищаем таймер при размонтировании компонента
    // }, []);

    return (
        <>
            <CanvasAnimation />
        </>
    );
}
